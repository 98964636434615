<template>
    <div
        class="gst-event-filter-modal d-flex flex-column"
        :class="{
            'rounded-t-1': $vuetify.breakpoint.mdAndUp,
            'rounded-t-lg': !$vuetify.breakpoint.mdAndUp,
        }">
        <HeaderVariant1
            class="flex-grow-0"
            :title="$t( '_common:terms.filter_plural' )"
            @close="$emit('close')" />
        <div class="gst-event-filter-modal__body pa-4">
            <span class="gst-event-filter-modal__sort-title pb-4 d-block">{{ $t('pricesAreIn') }} {{ priceFilter.currency }}</span>
            <RangeEditValuesInput
                v-model="priceFilter.range"
                :min="priceFilter.min"
                :max="priceFilter.max">
                <template v-slot:minValueLabel="slotProps">
                    {{
                        Math.ceil( slotProps.value ) | currencyFilter( priceFilter.currency, { digits: 0 }, false )
                    }}
                </template>
                <template v-slot:maxValueLabel="slotProps">
                    {{
                        Math.ceil( slotProps.value ) | currencyFilter( priceFilter.currency, { digits: 0 }, false )
                    }}+
                </template>
            </RangeEditValuesInput>
            <div>
                <span class="gst-event-filter-modal__sort-title py-4 d-block">{{ $t('_common:terms.moreFilters') }}</span>
                <AccessibilityToggleFormInput
                    v-model="accessibility"
                    :disabled="!options.accessibilityEnabled" />
            </div>
            <div class="mt-6">
                <v-btn
                    class="mb-4 text-capitalize"
                    block
                    color="primary"
                    @click="updateFilters()">
                    {{ $t('_common:buttons.applyFilters') }}
                </v-btn>
                <v-btn
                    class="text-capitalize"
                    block
                    color="primary"
                    text
                    @click="reset">
                    {{ $t('_common:buttons.reset') }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import CloseModalOnBreakpointChange from '@core/mixins/modals/CloseModalOnBreakpointChange';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import HeaderVariant1 from '@tenant/app/components/modals/HeaderVariant1';
    import RangeEditValuesInput from '@tenant/app/components/inputs/RangeEditValuesInput';
    import AccessibilityToggleFormInput from '../../_components/formInputs/AccessibilityToggleFormInput';

    export default {
        name: 'EventTicketFilterModal',
        components: {
            HeaderVariant1,
            RangeEditValuesInput,
            AccessibilityToggleFormInput
        },
        mixins: [
            CloseModalOnRouteChangeMixin,
            CloseModalOnBreakpointChange
        ],
        props: {
            value: {
                type: Object,
                required: true
            },
            options: {
                type: Object,
                required: true
            },
            busEventParent: {
                type: Object,
                default: null,
                required: false
            }
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.eventV2.theEvent._components.eventTicketFilterModal'
        },
        data() {
            return {
                priceFilter: {
                    ...this.value.price
                },
                accessibility: this.value.accessibility
            };
        },
        methods: {
            updateFilters() {
                this.busEventParent.$emit( 'update-filter', {
                    ...this.value,
                    accessibility: this.accessibility,
                    price: {
                        ...this.priceFilter
                    }
                } );
                this.$emit( 'close' );
            },
            reset( ) {
                this.busEventParent.$emit( 'reset-filter' );
                this.priceFilter = {
                    ...this.value.price
                };
                this.accessibility = this.value.accessibility;
            }
        }
    };
</script>

<style scoped lang="scss">
@import '@scssVariables';
@import '@scssMixins';

.gst-event-filter-modal {
    background-color: theme-color( 'white' );

    .gst-event-filter-modal__sort-title {
        @include font-size('l');

        color: theme-color( 'tertiary' );
    }
}
</style>
